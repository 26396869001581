import React from 'react';
import './styles.css';

import logoRBTMais from '../../assets/logorbtmais.png';

export default function Card({ name, document }) {

  function formatDocument(document) {
    if (document.length < 12)
      return `${document.substring(0, 3)}.${document.substring(3, 6)}.${document.substring(6, 9)}-${document.substring(9, 11)}`;
    else
      return `${document.substring(0, 2)}.${document.substring(2, 5)}.${document.substring(5, 8)}/${document.substring(8, 12)}-${document.substring(12, 14)}`;
  }

  const formattedDocument = formatDocument(document);

  return (
    <div className="card-container">
      <img src={logoRBTMais} alt="" />
      <div className="informations">
        <strong>{`NOME: ${name.toUpperCase()}`}</strong>
        <strong>{(document.length > 12 ? 'CNPJ: ' : 'CPF: ') + formattedDocument}</strong>
      </div>
    </div>
  )
}